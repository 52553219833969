@import '../../../../scss/theme-bootstrap';

/**
 * Generic typeahead
 */
.typeahead-wrapper {
  .esearch-nav-products {
    .esearch-product--typeahead {
      text-align: center;
      width: 100%;
      display: inline-block;
      vertical-align: top;
      padding: 14px 0 16px;
      border-bottom: 1px solid $color-lightest-grey;
      @include breakpoint($medium-up) {
        width: 32%;
        padding: 0 10px;
        border: none;
        background-color: $color-mpp-light-gray;
        margin-right: 1%;
        min-height: 480px;
        &:last-child {
          margin-right: 0;
        }
      }
      &--mobile {
        text-align: left;
        text-transform: capitalize;
      }
      .product-brief {
        &__image {
          max-width: 70px;
          display: inline-block;
          vertical-align: top;
          @include breakpoint($medium-up) {
            max-width: 100%;
          }
        }
        &__header {
          text-transform: capitalize;
        }
        &__info {
          display: inline-block;
          max-width: 70%;
          margin-top: 12px;
        }
        &__headline-link {
          .product-full--genaissance &,
          .mpp-container--dark-background & {
            color: $color-white;
            @include breakpoint($large-up) {
              color: initial;
            }
          }
        }
      }
    }
  }
  .see-results {
    @include text--2;
    clear: both;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    .result {
      background: none;
      padding: 0;
    }
    &--upper {
      text-align: center;
      padding: 12px 12px 16px;
      background-color: $color-white-smoke;
      @include breakpoint($medium-up) {
        text-align: left;
        background-color: unset;
        margin-top: 0;
      }
      .search-results {
        display: none;
      }
    }
    &--lower {
      padding: 15px 0 0;
      text-align: center;
      .search-count {
        display: none;
      }
      .search-results {
        text-decoration: underline;
      }
    }
  }
  .no-results {
    text-align: center;
    text-transform: none;
    line-height: 1.5;
    padding: 12px 4% 10px;
    @include breakpoint($large-up) {
      padding: 0 4%;
    }
    a {
      text-decoration: underline;
      color: $color-green;
      top: 0;
    }
  }
  @include breakpoint($medium-up) {
    .product-grid__content {
      border-top: 0;
    }
  }
}
